import { css } from '@emotion/react';

const GlobalStyles = (theme) => css`
  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0.5rem;
    background: ${theme.colors.scrollbar.track};
    border: 1px solid ${theme.colors.scrollbar.track};
    box-shadow: inset 0 0 0.375rem ${theme.colors.scrollbar.shadow};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background: ${theme.colors.scrollbar.thumb};
  }

  * {
    /* color: ${theme.colors.mainText}; */
  }
  svg {
    color: ${theme.colors.mainText};
  }
`;

export default GlobalStyles;
