import React from 'react';
import styled from '@emotion/styled';

import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CookieBanner = () => {
  const [open, setOpen] = React.useState(false);

  const { t } = useTranslation();

  React.useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setOpen(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      PaperProps={{
        sx: {
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          margin: 'auto',
          boxSizing: 'border-box',
        },
      }}
      disableEscapeKeyDown
      disableBackdropClick
    >
      <StyledDialogContent>{t('cookies.cookietext')}</StyledDialogContent>
      <StyledDialogActions>
        <Button onClick={handleAccept}>{t('global.buttons.accept')}</Button>
      </StyledDialogActions>
    </Dialog>
  );
};

export default CookieBanner;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  user-select: none;
  gap: 0.5rem;
  margin: 1rem 0 1rem 0;
  cursor: pointer;
  border-radius: 0.5rem;
  border: solid 1px ${(props) => props.theme.colors.button.border};
  background: none;
  color: ${(props) => props.theme.colors.button.text};

  transition: 0.2s ease;
  &:hover {
    background: ${(props) => props.theme.colors.button.hover};
  }
`;

const StyledDialogContent = styled(DialogContent)`
  background: ${(props) => props.theme.colors.background};
  text-align: center;
`;

const StyledDialogActions = styled(DialogActions)`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.background};
  justify-content: space-between;
`;
