import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Card,
  ToggleButton,
  ToggleButtonGroup,
  CardActionArea,
  CardMedia,
  CardContent,
  Stack,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';

const OnDemand = ({ isSmallScreen }) => {
  const { t } = useTranslation();
  const [videoLinks, setVideoLinks] = React.useState([]);
  const [selectedVideo, setSelectedVideo] = React.useState(null);
  const [viewMode, setViewMode] = React.useState('card');

  React.useEffect(() => {
    const fetchVideos = async () => {
      // const apiKey = 'AIzaSyBuzjQoL5hs2ZMUlXr49D9UhD6uCMWFzQY';
      const apiKey = 'AIzaSyCRSj-2eofuwWzTY70QpQST1Qi3A2bYvgI';
      const channelId = 'UC9PgeDA7eDb5vAY0aB8cU0g';
      const maxResults = 50;
      let videos = [];
      let nextPageToken = '';
      let baseUrl = `https://www.googleapis.com/youtube/v3/search?key=${apiKey}&channelId=${channelId}&part=snippet&type=video&maxResults=${maxResults}`;

      do {
        const url = `${baseUrl}&pageToken=${nextPageToken}`;
        const response = await fetch(url);
        const data = await response.json();

        data.items?.forEach((item) => {
          const videoId = item.id.videoId;
          const videoUrl = `https://www.youtube.com/watch?v=${videoId}`;
          const thumbnailUrl = item.snippet.thumbnails.medium.url;
          const title = item.snippet.title;
          const description = item.snippet.description;
          videos.push({ videoUrl, thumbnailUrl, title, description, videoId });
        });

        nextPageToken = data.nextPageToken;
      } while (nextPageToken);

      setVideoLinks(videos);

      // Automatically set the first video as the selected video
      if (videos.length > 0) {
        setSelectedVideo(videos[0]);
      }
    };

    setViewMode(window.localStorage.getItem('onDemandLayout'));
    fetchVideos();
  }, []);

  const handleViewChange = (event, newView) => {
    if (newView) {
      setViewMode(newView);
      window.localStorage.setItem('onDemandLayout', newView);
    }
  };

  return (
    <OndemandContainer>
      {selectedVideo && (
        <VideoPlayerContainer>
          <Stylediframe
            src={`https://www.youtube.com/embed/${selectedVideo.videoId}`}
            title={selectedVideo.title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></Stylediframe>
          {!isSmallScreen && (
            <AccordionContainer>
              <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <StyledVideoTitle>{selectedVideo.title}</StyledVideoTitle>
                </StyledAccordionSummary>
                <AccordionDetails>
                  <StyledVideoDescription>
                    {selectedVideo.description}
                  </StyledVideoDescription>
                </AccordionDetails>
              </StyledAccordion>
            </AccordionContainer>
          )}
        </VideoPlayerContainer>
      )}

      {!isSmallScreen && (
        <ViewToggleContainer>
          <StyledToggleButtonGroup
            value={viewMode}
            exclusive
            onChange={handleViewChange}
            aria-label="view mode"
          >
            <ToggleButton value="card" aria-label="card view">
              <ViewModuleIcon />
            </ToggleButton>
            <ToggleButton value="grid" aria-label="grid view">
              <ViewListIcon />
            </ToggleButton>
          </StyledToggleButtonGroup>
        </ViewToggleContainer>
      )}

      {videoLinks.length > 0 ? (
        isSmallScreen || viewMode === 'grid' ? (
          <GridContainer container spacing={2} expanded={viewMode === 'grid'}>
            {videoLinks.map((video, index) => (
              <Grid item xs={12} key={index}>
                <StyledAccordion>
                  <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <StyledVideoTitle>{video.title}</StyledVideoTitle>
                  </StyledAccordionSummary>
                  <AccordionDetails>
                    <Stack
                      //TODO: Add hover effect on the stack
                      direction={!isSmallScreen ? 'row' : 'column'}
                      gap={'1rem'}
                      isSmallScreen={isSmallScreen}
                      sx={{ alignItems: 'center' }}
                      onClick={() => {
                        setSelectedVideo(video);
                      }}
                    >
                      <StyledImage src={video.thumbnailUrl} alt={video.title} />
                      <StyledVideoDescription>
                        {video.description}
                      </StyledVideoDescription>
                    </Stack>
                  </AccordionDetails>
                </StyledAccordion>
              </Grid>
            ))}
          </GridContainer>
        ) : (
          <VideoGrid>
            {videoLinks.map((video, index) => (
              <VideoCard
                key={index}
                video={video}
                onClick={() => setSelectedVideo(video)}
              />
            ))}
          </VideoGrid>
        )
      ) : (
        <p>{t('Loading...')}</p>
      )}
    </OndemandContainer>
  );
};

const VideoCard = ({ video, onClick }) => (
  <StyledCard sx={{ maxWidth: 300, cursor: 'pointer' }} onClick={onClick}>
    <CardActionArea>
      <CardMedia
        component="img"
        height="140"
        image={video.thumbnailUrl}
        alt={video.title}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {video.title}
        </Typography>
      </CardContent>
    </CardActionArea>
  </StyledCard>
);

export default OnDemand;

const OndemandContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 20px;
`;

const Stylediframe = styled.iframe`
  width: 100%;
  height: 500px;
`;

const VideoPlayerContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
`;

const AccordionContainer = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const StyledAccordion = styled(Accordion)`
  background: ${(props) => props.theme.colors.secBackground};
  color: ${(props) => props.theme.colors.mainText};
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  .MuiAccordionSummary-content {
    justify-content: center;
  }
`;

const StyledVideoTitle = styled(Typography)`
  color: ${(props) => props.theme.colors.mainText};
  font-weight: 700;
  text-align: center;
`;

const StyledVideoDescription = styled(Typography)`
  color: ${(props) => props.theme.colors.secText};
  margin: 10px 0;
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  background: ${(props) => props.theme.colors.secBackground};
`;

const GridContainer = styled(Grid)`
  width: 100%;

  .MuiGrid-item:last-child {
    padding-bottom: ${({ expanded }) => (expanded ? '1rem' : '0')};
  }
`;

const VideoGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0 0 1rem 0;
  justify-content: center;
`;

const ViewToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledImage = styled.img`
  width: ${(props) => (props.isSmallScreen ? '100%' : '40%')};
  border-radius: ${(props) => (props.isSmallScreen ? '1rem' : '.25rem')};
  max-width: 400px;
`;

const StyledCard = styled(Card)`
  background: ${(props) => props.theme.colors.secBackground};
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 0.5rem;
  user-select: none;
  gap: 0.5rem;
  margin: 1rem 0 0 0;
  cursor: pointer;
  border-radius: 0.5rem;
  border: solid 1px ${(props) => props.theme.colors.button.border};
  background: none;
  color: ${(props) => props.theme.colors.button.text};

  transition: 0.2s ease;
  &:hover {
    background: ${(props) => props.theme.colors.button.hover};
  }
`;
