// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCSDfvk23LdI5Hxkl3ooFf9qna2Q5h7ZPw",
  authDomain: "irlwebpage.firebaseapp.com",
  projectId: "irlwebpage",
  storageBucket: "irlwebpage.appspot.com",
  messagingSenderId: "624658289191",
  appId: "1:624658289191:web:bd2c39d6ff112bbb73b402",
  measurementId: "G-4F5149N4EZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const db = getFirestore(app);