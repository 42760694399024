import React from 'react';
import styled from '@emotion/styled';
import {
  Switch,
  Menu,
  IconButton,
  Divider,
  // Avatar,
  // MenuItem,
  Chip,
} from '@mui/material';
import {
  Link,
  useLocation,
  // useNavigate
} from 'react-router-dom';
import {
  WbSunnyOutlined,
  NightlightOutlined,
  Settings,
  Menu as MenuOutlined,
  // LoginOutlined,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../utils/LanguageSelector';
// import LoginRegisterForm from './dialogs/LoginRegisterForm';
// import { auth } from '../firebase/config';
// import { signOut } from 'firebase/auth';
// import ResetPassword from './dialogs/ResetPassword';

const Navbar = ({ toggleTheme, isSmallScreen, showAlert, user, setUser }) => {
  // const navigate = useNavigate();
  const { t } = useTranslation();
  const [optionsAnchorEl, setOptionsAnchorEl] = React.useState(null);
  const [linksAnchorEl, setLinksAnchorEl] = React.useState(null);
  // const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState(null);
  const [isDarkMode, setIsDarkMode] = React.useState(false);
  // const [loginRegisterOpen, setLoginRegisterOpen] = React.useState(false);
  // const [showResetPassword, setShowResetPassword] = React.useState(false);
  const location = useLocation();

  const handleOptionsMenuOpenClick = (event) => {
    setOptionsAnchorEl(event.currentTarget);
  };
  const handleOptionsMenuCloseClick = () => {
    setOptionsAnchorEl(null);
  };

  const handleLinkMenuOpen = (event) => {
    setLinksAnchorEl(event.currentTarget);
  };

  const handleLinkMenuClose = () => {
    setLinksAnchorEl(null);
  };

  // const handleUserMenuOpen = (event) => {
  //   setUserMenuAnchorEl(event.currentTarget);
  // };

  // const handelUserMenuClose = () => {
  //   setUserMenuAnchorEl(null);
  // };

  React.useEffect(() => {
    const savedTheme = window.localStorage.getItem('theme');
    setIsDarkMode(savedTheme === 'dark');
  }, []);

  const handleThemeToggle = () => {
    setIsDarkMode((prevMode) => !prevMode);
    toggleTheme();
  };

  // const handleLoginRegisterOpen = () => {
  //   setLoginRegisterOpen(true);
  // };

  // const handleCloseLoginRegister = () => {
  //   setLoginRegisterOpen(false);
  // };

  // const handleLogoutUser = async () => {
  //   await signOut(auth);
  //   window.localStorage.removeItem('auth');
  //   setUser(null);
  //   navigate('/');
  // };

  // const openResetPassword = () => {
  //   setShowResetPassword(true);
  // };
  // const closeResetPassword = () => {
  //   setShowResetPassword(false);
  // };

  // const handleGoToEditUser = () => {
  //   navigate('/account');
  //   handelUserMenuClose();
  // };

  return (
    <Nav>
      <PageImage>
        <img
          src="https://static-cdn.jtvnw.net/jtv_user_pictures/0c8f38d6-aa1f-426d-b116-660757ca0954-profile_image-70x70.png"
          alt=""
          style={{ borderRadius: '50%', width: '50px', marginLeft: '0.5rem' }}
        />
      </PageImage>
      {!isSmallScreen ? (
        <Links>
          <StyledLink
            to="/"
            active={location.pathname === '/' ? 'true' : 'false'}
          >
            {t('navbar.links.home')}
          </StyledLink>
          <StyledLink
            to="/ondemand"
            active={location.pathname === '/ondemand' ? 'true' : 'false'}
          >
            {t('navbar.links.ondemand')}
          </StyledLink>
          <StyledLink
            to="/location"
            active={location.pathname === '/location' ? 'true' : 'false'}
          >
            {t('navbar.links.location')}
          </StyledLink>
          <StyledLink
            to="/calendar"
            active={location.pathname === '/calendar' ? 'true' : 'false'}
            onClick={handleLinkMenuClose}
          >
            {t('navbar.links.calendar')}
          </StyledLink>
        </Links>
      ) : (
        <>
          <IconButton
            aria-controls={Boolean(linksAnchorEl) ? 'links-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={Boolean(linksAnchorEl) ? 'true' : undefined}
            onClick={handleLinkMenuOpen}
          >
            <MenuOutlined
              sx={{ color: `${(props) => props.colors.mainText}` }}
            />
          </IconButton>
          <Menu
            id="links-menu"
            anchorEl={linksAnchorEl}
            open={Boolean(linksAnchorEl)}
            onClose={handleLinkMenuClose}
            sx={{
              textAlign: 'center',
            }}
          >
            <Links
              style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
              isSmallScreen={isSmallScreen}
            >
              <StyledLink
                to="/"
                active={location.pathname === '/' ? 'true' : 'false'}
                onClick={handleLinkMenuClose}
              >
                {t('navbar.links.home')}
              </StyledLink>
              <StyledLink
                to="/ondemand"
                active={location.pathname === '/ondemand' ? 'true' : 'false'}
                onClick={handleLinkMenuClose}
              >
                {t('navbar.links.ondemand')}
              </StyledLink>
              <StyledLink
                to="/location"
                active={location.pathname === '/location' ? 'true' : 'false'}
                onClick={handleLinkMenuClose}
              >
                {t('navbar.links.location')}
              </StyledLink>
              <StyledLink
                to="/calendar"
                active={location.pathname === '/calendar' ? 'true' : 'false'}
                onClick={handleLinkMenuClose}
              >
                {t('navbar.links.calendar')}
              </StyledLink>
            </Links>
          </Menu>
        </>
      )}
      <PageHeader>{!isSmallScreen ? t('navbar.header') : ''}</PageHeader>
      {/* <Login>
        {user ? (
          <>
            <Avatar
              aria-controls={
                Boolean(userMenuAnchorEl) ? 'user-menu' : undefined
              }
              aria-haspopup="true"
              aria-expanded={Boolean(userMenuAnchorEl) ? 'true' : undefined}
              onClick={handleUserMenuOpen}
              sx={{ cursor: 'pointer' }}
            >
              {user.displayName.charAt(0)}
            </Avatar>
            <Menu
              id="user-menu"
              anchorEl={userMenuAnchorEl}
              open={Boolean(userMenuAnchorEl)}
              onClose={handelUserMenuClose}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <MenuItem>
                {console.log(user.emailVerified)}
                <StyledChip
                  verified={user.emailVerified}
                  label={user.displayName}
                  variant="outlined"
                  onClick={handleGoToEditUser}
                />
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleLogoutUser} sx={{ gap: '.5rem' }}>
                <LoginOutlined />
                {t('global.buttons.logout')}
              </MenuItem>
            </Menu>
          </>
        ) : (
          <Button onClick={handleLoginRegisterOpen}>
            <LoginOutlined />
            {!isSmallScreen ? t('global.buttons.login') : ''}
          </Button>
        )}
      </Login> */}
      <Options>
        <IconButton
          aria-controls={Boolean(optionsAnchorEl) ? 'options-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={Boolean(optionsAnchorEl) ? 'true' : undefined}
          onClick={handleOptionsMenuOpenClick}
        >
          <Settings sx={{ color: `${(props) => props.colors.mainText}` }} />
        </IconButton>
        <Menu
          id="options-menu"
          anchorEl={optionsAnchorEl}
          open={Boolean(optionsAnchorEl)}
          onClose={handleOptionsMenuCloseClick}
        >
          <LanguageSelector />
          <Divider />
          <ToggleContainer>
            <WbSunnyOutlined />
            <ToggleThemeSwitch
              checked={isDarkMode}
              onChange={handleThemeToggle}
            />
            <NightlightOutlined />
          </ToggleContainer>
        </Menu>
      </Options>
      {/* {loginRegisterOpen && (
        <LoginRegisterForm
          showAlert={showAlert}
          openLoginRegisterDialog={loginRegisterOpen}
          closeLoginRegisterDialog={handleCloseLoginRegister}
          openResetPassword={openResetPassword}
          isSmallScreen={isSmallScreen}
          setUser={setUser}
        />
      )}
      {showResetPassword && (
        <ResetPassword
          showAlert={showAlert}
          openResetPassword={showResetPassword}
          closeResetPassword={closeResetPassword}
        />
      )} */}
    </Nav>
  );
};

export default Navbar;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.container.background};
  padding: 0.5rem;
  color: ${(props) => props.theme.colors.mainText};

  a {
    color: ${(props) => props.theme.colors.mainText};
    margin-right: 1rem;
    text-decoration: none;
  }
`;

const PageImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Links = styled.div`
  margin-left: ${(props) => (props.isSmallScreen ? '' : '2rem')};
  margin: ${(props) => (props.isSmallScreen ? '0.5rem 1rem 0.5rem 1rem' : '')};
  flex: 1;
`;

const StyledLink = styled(Link)`
  transition: 0.2s ease;
  text-decoration: none;
  text-shadow: ${(props) =>
    props.active === 'true' ? '2px 2px 3px #1ffad8' : 'none'};
  &:hover {
    text-shadow: 2px 2px 3px #1ffad8;
  }
`;

const PageHeader = styled.div`
  text-align: center;
  flex: 3;
`;
// const Login = styled.div`
//   display: flex;
//   gap: 1rem;
//   margin-right: 1rem;
// `;

// const Button = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 0.5rem;
//   user-select: none;
//   gap: 0.5rem;
//   cursor: pointer;
//   border-radius: 0.5rem;
//   border: solid 1px ${(props) => props.theme.colors.button.border};

//   transition: 0.2s ease;
//   &:hover {
//     background: ${(props) => props.theme.colors.button.hover};
//   }
// `;

const Options = styled.div``;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
`;

const ToggleThemeSwitch = styled(Switch)`
  &.MuiSwitch-switchBase.Mui-checked {
    &:hover {
      background-color: ${(props) => props.theme.colors.switch.hover};
    }
  }
  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background: ${(props) => props.theme.colors.switch.background};
  }
`;

// const StyledChip = styled(Chip)`
//   border-color: ${(props) => (props.verified === false ? 'orange' : '#1ffad8')};
// `;
