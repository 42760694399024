import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import './scripts/i18n';
import { I18nextProvider } from 'react-i18next';
import i18n from './scripts/i18n';

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>
);