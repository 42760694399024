import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Standard-Icon setzen
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const Location = () => {
  const { t } = useTranslation();

  // Verwende lat und long, oder setze Standardwerte wie Berlin
  const lat = 52.52; // Beispiel-Koordinaten für Berlin
  // const lat = 48.129389681021145;
  const long = 13.405; // Beispiel-Koordinaten für Berlin
  // const long = 12.938144770839562; // Beispiel-Koordinaten für Berlin

  return (
    <MapContainerStyle>
      <StyledMapContainer center={[lat, long]} zoom={13}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={[lat, long]}>
          <Popup>{t('location.popup')}</Popup>
        </Marker>
      </StyledMapContainer>
    </MapContainerStyle>
  );
};

export default Location;

const MapContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
`;

const StyledMapContainer = styled(MapContainer)`
  height: 100%;
  width: 100%;
`;
