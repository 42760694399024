import React from 'react';
import styled from '@emotion/styled';

const Calendar = () => {
  return (
    <CalendarContainer>
      <StyledIfram
        title="calendar"
        src="https://calendar.google.com/calendar/embed?src=dbaec5c922b1f082445dc90f1f0da42e3080b084aeda0a228a55fb589d913409%40group.calendar.google.com&ctz=Europe%2FAmsterdam"
      ></StyledIfram>
    </CalendarContainer>
  );
};

export default Calendar;

const CalendarContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
`;

const StyledIfram = styled.iframe`
  border: 0;
  width: 100%;
  height: 100%;
`;
