import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select } from '@mui/material';

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Container>
      <StyledSelect
        size="small"
        onChange={(e) => changeLanguage(e.target.value)}
        value={i18n.language}
        variant="outlined"
        inputProps={{
          MenuProps: {
            PaperProps: {
              sx: (theme) => theme.components.MuiMenu.styleOverrides.paper,
            },
          },
        }}
      >
        <MenuItem value="de">De</MenuItem>
        <MenuItem value="en">En</MenuItem>
      </StyledSelect>
    </Container>
  );
};

export default LanguageSelector;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
  padding-top: 0.5rem;
`;

const StyledSelect = styled(Select)`
  border: lightgray;
  width: 85%;
  color: ${(props) => props.theme.colors.mainText};
  border: solid 1px #555;
  border-radius: 0.25rem;
  background: ${(props) => props.theme.colors.select.background};
  & .MuiSvgIcon-root {
    fill: ${(props) => props.theme.colors.mainText};
  }
  &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: lightgray;
  }
`;
