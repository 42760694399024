import { createTheme } from "@mui/material";

export const lightTheme = createTheme({
  colors: {
    background: '#d4d3d3',
    secBackground: '',
    mainText: '#000000',
    secText: '#414141',
    placeholder: '#999',
    loading: '#90ee90',
    scrollbar: {
      track: '#e6e6e6',
      thumb: '#333',
      shadow: '#0000004a'
    },
    switch: {
      background: '#bababa',
      hover: '#a0a0a0',
      opacity: '#90ee9005',
      track: ''
    },
    select: {
      background: '#DDDDDD'
    },
    button: {
      border: '#bfbfbf',
      hover: '#f1f1f1'
    },
    input: {
      border: '#bfbfbf'
    }
  },
  container: {
    background: '#ffffff',
  },
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: '#e6e6e6',
          color: '#000000',
          '& .MuiMenuItem-root.Mui-selected': {
            backgroundColor: '#DDDDDD',
          },
          '& .MuiMenuItem-root:hover': {
            backgroundColor: '#DDDDDD',
          },
          '& .MuiMenuItem-root.Mui-selected:hover': {
            backgroundColor: '#DDDDDD',
          },
        },
      },
    },
  }
});

export const darkTheme = createTheme({
  colors: {
    background: '#414141',
    secBackground: '#414141',
    mainText: '#ffffff',
    secText: '#d4d3d3',
    placeholder: '#999',
    loading: '#90ee90',
    scrollbar: {
      track: '#333',
      thumb: '#e6e6e6',
      shadow: '#ffffff49'
    },
    switch: {
      background: '#90ee90',
      hover: 'rgba(144, 238, 144, 0.02)',
      opacity: '#90ee9005',
      track: ''
    },
    select: {
      background: '#333'
    },
    button: {
      border: '#1ffad973',
      hover: 'rgba(31, 250, 217, 0.056)'
    },
    input: {
      border: ''
    }
  },
  container: {
    background: '#18181b',
  },
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: '#333',
          color: 'white',
          '& .MuiMenuItem-root.Mui-selected': {
            backgroundColor: '#313131',
          },
          '& .MuiMenuItem-root:hover': {
            backgroundColor: '#313131',
          },
          '& .MuiMenuItem-root.Mui-selected:hover': {
            backgroundColor: '#313131',
          },
        },
      },
    },
  }
});