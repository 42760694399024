import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <StyledLink to="/agb">{t('navbar.links.agbs')}</StyledLink>
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  text-align: right;
`;

const StyledLink = styled(Link)`
  margin-right: 0;
  color: ${(props) => props.theme.colors.mainText};
  margin-right: 1rem;
  text-decoration: none;
  font-size: 10px;
`;
