import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styled from '@emotion/styled';
import { lightTheme, darkTheme } from './scripts/themes';
import GlobalStyles from './scripts/GlobalStyles';
import { ThemeProvider } from '@mui/material';
import Navbar from './components/Navbar';
// import SideMenu from './components/SideMenu';
import Home from './pages/Home';
import AGB from './pages/AGB';
import { Global } from '@emotion/react';
import OnDemand from './pages/OnDemand';
import Location from './pages/Location';
import Footer from './components/Footer';
import Alerts from './utils/Alerts';
// import { auth } from './firebase/config';
import EditUser from './pages/EditUser';
import Calendar from './pages/Calendar';
import CookieBanner from './components/CookieBanner';

//TODO: Create a User Information component.

const App = () => {
  const [theme, setTheme] = React.useState();
  const [isSmallScreen, setIsSmallScreen] = React.useState(false);
  const [windowWidth, setWindowWidth] = React.useState(window.windowWidth);
  const [alert, setAlert] = React.useState(false);
  const [alertMessage, setAlertrMessage] = React.useState('');
  const [alertTitle, setAlertTitle] = React.useState('');
  const [direction, setDirection] = React.useState('');
  const [severity, setSeverity] = React.useState('');
  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    setTheme(window.localStorage.getItem('theme'));
    setWindowWidth(window.innerWidth);

    const handleResize = () => {

      if (window.innerWidth <= 1000) {
        setIsSmallScreen(true)
      }
      if (window.innerWidth > 1000) {
        setIsSmallScreen(false);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    const authData = window.localStorage.getItem('auth');
    if (authData) {
      setUser(JSON.parse(authData));
    }
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, [])
  console.log({ user: user });

  const toggleTheme = () => {
    setTheme((prevTheme) => {
      const newTheme = prevTheme === 'light' ? 'dark' : 'light';
      window.localStorage.setItem('theme', newTheme);
      return newTheme;
    });
  };

  const showAlert = ({ message, title, duration = 5, direction, alertType }) => {
    setAlertrMessage(message);
    setAlertTitle(title)
    setDirection(direction)
    setSeverity(alertType)
    setAlert(true);

    const timer = setTimeout(() => {
      setAlert(false);
    }, duration * 1000);

    return () => clearTimeout(timer);
  };

  return (
    <>
      <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
        <Global styles={(theme) => GlobalStyles(theme)} />
        <Container>
          <CookieBanner />
          <Router>
            <Navbar toggleTheme={toggleTheme} isSmallScreen={isSmallScreen} showAlert={showAlert} user={user} setUser={setUser} />
            <MainContent>
              <BodyContainer>
                <BodyLeft>
                  <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/ondemand' element={<OnDemand user={user} setUser={setUser} isSmallScreen={isSmallScreen} showAlert={showAlert} />} />
                    <Route path='/location' element={<Location />} />
                    <Route path='/calendar' element={<Calendar />} />
                    <Route path='/account' element={<EditUser user={user} setUser={setUser} isSmallScreen={isSmallScreen} showAlert={showAlert} />} />
                    <Route path='/agb' element={<AGB />} />
                  </Routes>
                </BodyLeft>
                {/* {!isSmallScreen && (
                  <BodyRight>
                  <SideMenu theme={theme} />
                  </BodyRight>
                  )} */}
              </BodyContainer>
              <Footer />
            </MainContent>
          </Router>
        </Container>
        {alert && (
          <Alerts
            event={alert}
            title={alertTitle}
            direction={direction}
            message={alertMessage}
            severity={severity}
          />
        )}
      </ThemeProvider>
    </>
  );
}

export default App;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colors.mainText};
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  padding: 1rem;
  box-sizing: border-box;
  background: ${(props) => props.theme.colors.background};
`;

const BodyContainer = styled.div`
  display: flex;
  grid-template-columns: 2fr 400px;
  gap: 1rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
`;

// const BodyRight = styled.div`
//   flex:1;
//   background: ${(props) => props.theme.container.background};
//   border: 1px solid #111;
//   overflow: hidden;
//   max-width: 400px;
// `;

const BodyLeft = styled.div`
  flex: 1;
  background: ${(props) => props.theme.container.background};
  border: 1px solid #111;
  overflow-y: auto;
  display: flex;
  flex-direction: column; /* Ensure that children elements are arranged in a column */
  height: 100%; /* Ensure it takes up the full height */
`;
