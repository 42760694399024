import React from 'react';
import styled from '@emotion/styled';

const AGB = () => {
  return <PageContainer>This is the AGB page.</PageContainer>;
};

export default AGB;

const PageContainer = styled.div`
  padding: 1rem;
  overflow-y: auto;
  height: 100%;
`;
