import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation();
  return (
    <PageContainer>
      <h1 className="pulsating-header">{t('global.info')}</h1>
    </PageContainer>
  );
};

export default Home;

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
`;
