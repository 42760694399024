import React from 'react';
import styled from '@emotion/styled';
import { Alert, AlertTitle, Slide } from '@mui/material';

const Alerts = ({
  event,
  title = 'Title',
  direction = 'left',
  message = 'Alert message',
  severity = 'success',
}) => {
  const containerRef = React.useRef(null);
  return (
    <Slide
      direction={direction}
      in={event}
      container={containerRef.current}
      mountOnEnter
      unmountOnExit
    >
      <StyledAlert
        sx={{
          position: 'fixed',
          right: '1%',
          bottom: '1%',
          transform: 'translateX(-50%)',
          zIndex: 999,
        }}
        variant="outlined"
        severity={severity}
      >
        {title !== '' && <AlertTitle>{title}</AlertTitle>}
        {message}
      </StyledAlert>
    </Slide>
  );
};

export default Alerts;

const StyledAlert = styled(Alert)`
  color: ${(props) => props.theme.colors.mainText};
  background: ${(props) => props.theme.container.background};
`;
