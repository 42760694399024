import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Chip, Link, Paper, Stack, TextField } from '@mui/material';
import { sendEmailVerification } from 'firebase/auth';
import { auth } from '../firebase/config';

const EditUser = ({ isSmallScreen, showAlert, user, setUser }) => {
  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = React.useState(null);
  const [newPassword, setNewPassword] = React.useState(null);
  const [confirmPassword, setConfir, Password] = React.useState(null);
  const [oldEmail, setOldEmail] = React.useState(null);
  const [emailVerified, setemailVerified] = React.useState(null);
  const [newEmail, setEmail] = React.useState(null);
  const [confirmEmail, setConfirmEmail] = React.useState(null);
  const [newNick, setNewNick] = React.useState();

  React.useEffect(() => {
    setOldEmail(user?.email);
    setemailVerified(user?.emailVerified);
  }, [user]);

  const handleSendVerificationEmail = async () => {
    try {
      console.log(auth.currentUser);
      await sendEmailVerification(auth.currentUser);
      showAlert(
        {
          message: t('alerts.message.sendverificationemail'),
          title: t('alerts.header.success'),
          alertType: 'success',
        },
        10
      );
    } catch (error) {
      console.log(error.message);
      showAlert({
        message: t('alerts.message.somethingwentwrong'),
        title: t('alerts.header.error'),
        alertType: 'error',
      });
    }
  };

  const handleOnClick = (type) => {
    switch (type) {
      case 'password':
        break;
      case 'email':
        break;
      case 'nick':
        break;
      default:
        break;
    }
  };

  return (
    <Container>
      <StyledPaper>
        <SubHeader>{t('edituser.email.header')}</SubHeader>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Label>
            {t('edituser.email.currentemail')}: <br /> {oldEmail}
          </Label>
          <Stack gap=".5rem">
            <Chip
              color={emailVerified ? 'success' : 'error'}
              label={t('edituser.email.verified')}
              size="small"
            />
            {!emailVerified && (
              <LinkButton onClick={handleSendVerificationEmail}>
                {t('edituser.email.sendverificationemail')}
              </LinkButton>
            )}
          </Stack>
        </Stack>
        <Stack
          direction={!isSmallScreen ? 'row' : 'column'}
          gap="1rem"
          marginTop="1rem"
        >
          <StyledTextField
            id="email"
            name="email"
            label={t('edituser.email.email')}
            variant="outlined"
            size="small"
            fullWidth
            required
          />
          <StyledTextField
            id="confirmemail"
            name="confirmemail"
            label={t('edituser.email.confirmemail')}
            variant="outlined"
            size="small"
            fullWidth
            required
          />
        </Stack>
        <Button onClick={handleOnClick('email')}>
          {t('global.buttons.save')}
        </Button>
      </StyledPaper>
      <StyledPaper>
        <SubHeader>{t('edituser.nick.header')}</SubHeader>
        <Stack direction={'column'} gap={'.5rem'}>
          <StyledTextField
            id="display-name"
            name="displayName"
            label={t('register.displayname')}
            variant="outlined"
            size="small"
            fullWidth
            required
          />
        </Stack>
        <Button onClick={handleOnClick}>{t('global.buttons.save')}</Button>
      </StyledPaper>
    </Container>
  );
};

export default EditUser;

const Container = styled.div`
  padding: 1rem;
`;

const SubHeader = styled.h3`
  margin-bottom: 1rem;
`;

const Label = styled.label`
  font-size: 16px;
`;

const StyledPaper = styled(Paper)`
  padding: 1rem;
  background: ${(props) => props.theme.colors.secBackground};
  margin-top: 1rem;
  &:first-of-type {
    margin-top: 0;
  }
`;

const LinkButton = styled(Link)`
  cursor: pointer;
  font-size: 12px;
`;

const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    color: ${(props) => props.theme.colors.mainText};
    & fieldset {
      border-color: ${(props) => props.theme.colors.input.border};
    }

    &:hover fieldset {
      border-color: ${(props) => props.theme.colors.input.border};
    }

    &.Mui-focused fieldset {
      border: solid 1px ${(props) => props.theme.colors.input.border};
    }
  }

  & .MuiInputLabel-root {
    color: ${(props) => props.theme.colors.placeholder};
  }

  & .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.colors.placeholder};
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  user-select: none;
  gap: 0.5rem;
  margin: 1rem 0 0 0;
  cursor: pointer;
  border-radius: 0.5rem;
  border: solid 1px ${(props) => props.theme.colors.button.border};
  background: none;
  color: ${(props) => props.theme.colors.button.text};

  transition: 0.2s ease;
  &:hover {
    background: ${(props) => props.theme.colors.button.hover};
  }
`;
